import Rails from "@rails/ujs"
export const Offer = {
  initialize: function() {
    if ($("#offer").length) {
      Offer.bindShowText()
      Offer.scrollRightSidebar()
      Offer.bindSlider()
      Offer.bindCalculator()
    }
  },

  bindShowText: function() {
    $('.show-text').click(function(){
      $('.description').addClass('show')
      $(".show-text").hide()
    })
    $('.description .hide-text').click(function(){
      $('.description').removeClass('show')
      $(".show-text").show()
    })
  },

  scrollRightSidebar: function() {
    $(window).bind('scroll resize', function(){
      if (168 < $(window).scrollTop()) {
        if ($(window).scrollTop() > ($("#offer").height() - 624)) {
          $('#offer .right-sidebar').addClass('absolute-bottom')
          $('#offer .right-sidebar').removeClass('fixed')
        } else {
          $('#offer .right-sidebar').addClass('fixed')
          $('#offer .right-sidebar').removeClass('absolute-bottom')
        }
      } else {
        $('#offer .right-sidebar').removeClass('fixed').removeClass('absolute-bottom')
      }
    })
  },

  bindSlider: function() {
    var slider_for = $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: '.slider-nav'
    });
    $('.slider-for').slickLightbox({
      src: function(element) { 
        if ($(window).width() > 756) {
          return $(element).attr("data-full-src"); 
        } else {
          return $(element).attr("data-mobile-src"); 
        }
      }, 
      itemSelector: '.box .photo img',
      lazy: true,
    }).on({
      'show.slickLightbox': function(){ 
        $("body").css("overflow", "hidden") 
        $('.slick-lightbox-slick-item').bind('swipedown', function(e) {
          $(".slick-lightbox .slick-lightbox-close").click()
        });
      },
      'hide.slickLightbox': function(){ $("body").css("overflow", "") }
    })

    $('.slider-nav').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1339,
          settings: {
            slidesToShow: 3,
          }
        },
      ]
    });
    $('.similar').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      focusOnSelect: false,
      vertical: false,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: false,
            swipe: false,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            draggable: false,
            swipe: false,
          }
        },
      ]
    });
    $(document).on('keydown', function(e) {
      if(e.keyCode == 37) {
        slider_for.slick('slickPrev');
      }
      if(e.keyCode == 39) {
        slider_for.slick('slickNext');
      }
    });
  },

  bindCalculator: function (){
    var form   = $("#get-offer-calculation");
    var sliders = form.find("input[type=range]");
    var inputs = form.find("input[type=number], select");

    sliders.bind("input", function() {
      if ($(this).attr("name") == "period") {
        if ($(this).val() < 7) {
          $(this).val(7);
          var val_for_other = 7;
        } else {
          var val_for_other = $(this).val();
        }
        $("#hidden_period").val(val_for_other);
      } else {
        var val_for_other = $(this).val();
      }

      var other = form.find(".inputs [name='" + this.name + "']");
      other.val(val_for_other);
    });

    sliders.bind("change", function() {
      Rails.fire(form[0], 'submit');
    });

    $("[name=amount][type=number]").change(function(){
      if (isNaN(parseFloat(this.value))) {
        $(this).val(this.max)
      } else if (parseFloat(this.value) > parseFloat(this.max)) {
        $(this).val(this.max)
      } else if (parseFloat(this.value) < parseFloat(this.min)) {
        $(this).val(this.min)
      }

      var remaining = parseFloat($("#remaining-amount").data().price) - parseFloat($(this).val());
      $("#remaining-amount").text(remaining.toLocaleString("en").replace(",", " "));
    });

    $("[name=amount][type=range]").change(function(){
      var remaining = parseFloat($("#remaining-amount").data().price) - parseFloat($(this).val());
      $("#remaining-amount").text(remaining.toLocaleString("en").replace(",", " "));
    });

    inputs.change(function() {
      var other = form.find(".sliders [name='" + this.name + "']")
      other.val($(this).val())
      Rails.fire(form[0], 'submit');
    });

    form.bind("ajax:before", function(event){
      $(".installment-section .fa-spinner").show();
      $(".installment-section .amount-container").hide();

      $(".installment-section .error").text("");
      $(".installment-section .error").hide();
    });

    var handleError = function(){
      $("#installment").text("błąd pobierania wyliczenia");
      $(".installment-section .amount-container").hide();
      $("#credit_details").hide();

      $(".installment-section .error").text("Przepraszamy, wystąpił błąd");
      $(".installment-section .error").show();
      $(".installment-section .fa-spinner").hide();
    }

    form.bind("ajax:error", function(event){
      handleError();
    })

    form.bind("ajax:success", function(event){
      var data = event.originalEvent.detail[0];

      var credit_cost_rate_annual = Math.round((data.credit_cost_rate_annual * 100) * 100) / 100;
      var interest_rate_annual = Math.round((data.interest_rate_annual * 100) * 100) / 100;

      var installment = parseInt(data.payment_amount_monthly)

      var valid = !isNaN(installment);

      if (valid) {
        $(".installment-section .error").text("");
        $(".installment-section .error").hide();
        $(".installment-section .amount-container").show();
        $("#credit_details").show();

        $("#installment").text(installment.toLocaleString("en").replace(",", " "))
        $("#credit_cost_rate_annual").text(credit_cost_rate_annual)
        $("#interest_rate_annual").text(interest_rate_annual)
        $("#total_cost").text(parseInt(data.total_cost).toLocaleString())
        $("#total_cost_of_credit").text(parseInt(data.total_cost_of_credit).toLocaleString())

        $(".installment-section .amount-container").show();
        $(".installment-section .fa-spinner").hide();
      } else {
        handleError();
      }

    });

    $("a.monthly-installment").click(function(){
      $(window).scrollTop($("#wylicz-rate").offset().top - 35);
    });

  }

}
