export const Form = {
  email_regex: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,

  initialize: function() {

    $("input[name*=phone_number]").keypress( function(e) {
      return /[0-9 +/()]/.test(e.key)
    });
    $("input[name*=email]").keypress( function(e) {
      return /\S/.test(e.key)
    });

    $("#contact-form").click(function(e) {
      if (e.target.id == 'contact-form') {
        $('#contact-form').hide()
      }
    });

    $(".show-term").click(function() {
      var css_class = $(this).data('class')
      var box = $(this).parents('form').find('.'+css_class)
      if (box.is(':visible')) {
        box.slideUp()
      } else {
        box.slideDown()
      }

    })


    $(".form form").on('ajax:beforeSend', function() {
      $(this).find('.form-group').removeClass('invalid')

      if ($(this).find('select[name*=topic]').val() == null) {
        $(this).find('select[name*=topic]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('select[name*=location]').val() == null) {
        $(this).find('select[name*=location]').parents('.form-group').addClass('invalid')
      }
      if ($("#vehicle-repurchase form").length) {
        if ($(this).find('input[name*=brand]').val() == "") {
          $(this).find('input[name*=brand]').parents('.form-group').addClass('invalid')
        }
      } else {
        if ($(this).find('select[name*=brand]').val() == null) {
          $(this).find('select[name*=brand]').parents('.form-group').addClass('invalid')
        }
      }
      if ($(this).find('input[name*=first_name]').val() == "") {
        $(this).find('input[name*=first_name]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('input[name*=last_name]').val() == "") {
        $(this).find('input[name*=last_name]').parents('.form-group').addClass('invalid')
      }
      if ($(this).find('input[name*=mileage]').val() == "") {
        $(this).find('input[name*=mileage]').parents('.form-group').addClass('invalid')
      }

      if ($(this).find('input[name*=email]').val() == "") {
        $(this).find('input[name*="[email]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[email]"]').parents('.form-group').find("span.error").text("Proszę podać email")
      } else if (!Form.email_regex.test($(this).find('input[name*=email]').val())) {
        $(this).find('input[name*="[email]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[email]"]').parents('.form-group').find("span.error").text("Błędny format adresu email")
      }

      if ($(this).find('input[name*=phone_number]').val() == "") {
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').find("span.error").text("Proszę podać nr telefonu")
      } else if (!/^[0-9 +/()]+$/.test($(this).find('input[name*=phone_number]').val())) {
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').addClass('invalid')
        $(this).find('input[name*="[phone_number]"]').parents('.form-group').find("span.error").text("Błędny format nr telefonu")
      }

    //if (!$(this).find("input[name*=term_1_email][type=checkbox]").prop("checked") && !$(this).find("input[name*=term_2_phone][type=checkbox]").prop("checked")) {
    //  $(this).find("input[name*=term_1_email]").parents('.form-group').addClass('invalid')
    //  $(this).find("input[name*=term_2_phone]").parents('.form-group').addClass('invalid')
    //}

    //if (!$(this).find("input[name*=term_7_information_obligation][type=checkbox]").prop("checked")) {
    //  $(this).find("input[name*=term_7_information_obligation]").parents('.form-group').addClass('invalid')
    //}

    //if ($(this).find(".form-group.invalid input[name*=term]").length && !$(this).find(".terms").is(":visible")) {
    //  $(this).find(".terms").slideDown()
    //}

      if (this.id == "calculator") {
        if (!$(this).find("input[name*=term_2_phone][type=checkbox]").prop("checked")) {
          $(this).find("input[name*=term_2_phone]").parents('.form-group').addClass('invalid')
        }

        if (!$(this).find("input[name*=term_7_information_obligation][type=checkbox]").prop("checked")) {
          $(this).find("input[name*=term_7_information_obligation]").parents('.form-group').addClass('invalid')
        }

        if ($(this).find(".form-group.invalid input[name*=term]").length && !$(this).find(".terms").is(":visible")) {
          $("#show-term").slideDown()
        }
      }


      if ($(this).find('.form-group.invalid').length) {
        return false
      } else {
        return true
      }
    });

    $(".form form").submit(function() {
      if ($(this).find("input[name=recaptcha_token]").length) {
        $(this).find("input[name=recaptcha_token]").val($("#recaptcha_badge").attr("data-recaptcha-token"))
      } else {
        $(this).append($('<input>', { type: 'hidden', name: 'recaptcha_token', value: $("#recaptcha_badge").attr("data-recaptcha-token") }))
      }
    })

    $(".form form").on('ajax:success', function(event) {
      var response = event.detail[0]
      var hash = {}
      var data = []
      $(this).find("[class*='yl-']").each(function(i, input) {
        var class_name = $(input).attr('class').split(" ").filter(function(i,y) { return i.indexOf('yl-') === 0 })[0]
        if ($(input).is(':radio')) {
          hash[class_name] = ($('.'+class_name+':checked').val() == "1")
        } else if ($(input).is(':checkbox')) {
          hash[class_name] = $(input).prop("checked")
        } else {
          hash[class_name] = $(input).val()
        }
      })
      $.each(hash, function(k,v) { data.push({[k]: v}) })
      try { Monitor.TrackForm(($(this).attr('id')+"_bravoauto"), 3, 2, data) } catch(err) { console.log("YL errors") }
      $(this).find(".close").click()
      if (response["inspection_pdf_url"]) {
        $("body").append('<a href="'+response["inspection_pdf_url"]+'" target="_blank" id="open_inspection_pdf"></a>')
        $("#note_success_form p").html('Certyfikat powinien automatycznie otworzyć się w nowej karcie.<br>' +
        'Jeśli pobieranie nie rozpoczęło się automatycznie, proszę ' +
        '<a href="'+response["inspection_pdf_url"]+'" target="_blank" id="open_inspection_pdf">kliknąć tutaj</a>.')
        $("#open_inspection_pdf")[0].click()
      } else {
        $("#note_success_form p").html("Wiadomość została wysłana.<br>Nasz konsultant skontaktuje się z Tobą.")
      }
      $("#note_success_form").show()
      $("#note_success_form").append('<script type="text/javascript">var getCookie=function(e){for(var t=e+"=",r=document.cookie.split(";"),n=0;n<r.length;n++){for(var s=r[n];" "==s.charAt(0);)s=s.substring(1);if(0==s.indexOf(t))return s.substring(t.length,s.length)}return""},getUserId=function(){var e=getCookie("twd_u");return""===e?"":e},userId=getUserId(),xhr=new XMLHttpRequest;xhr.open("GET",https://trafficscanner.pl/ws2/index.php/?action=noscript&dt=77&cid=3058&user_id=+userId),xhr.send(); </script><script type="text/javascript" src=https://trafficscanner.pl/ws2/script/setSaleCookie.js></script> ')

      $(this).find('input.form-control:not([type=hidden])').val("")
      $(this).find("select,textare").val("")
      $(this).find("input[type=checkbox]").prop("checked", false)
      $(this).find("#files .file").remove()
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': $(this).find("input[name*=form_kind]").val()
      });
      window.getCaptchaToken();
    })

    $('.show-popup-form').click(function(){
      var options = []
      $("#contact-form form").attr("id", $(this).data('list'))
      $("#contact-form #client-topic option").remove()
      if ($(this).data('list') == 'main') {
        options = [
          "Wybierz temat wiadomości",
          "Jazda testowa",
          "Pojazdy nowe",
          "Pojazdy używane",
          "Odkup pojazdu",
          "Oferta finansowania",
          "Usługi serwisowe",
          "Inne zapytanie do działu obsługi klienta",
        ]
      } else if ($(this).data('list') == 'financing') {
        options = [
          "Leasing",
          "Comfort leasing",
          "Kredyt",
          "Ubezpieczenia komunikacyjne",
          "Service Inclusive",
          "Assistance",
        ]
      } else if ($(this).data('list') == 'detailing') {
        options = [ "Detailing" ]
      } else if ($(this).data('list') == 'loyalty_program') {
        options = [ "Program lojalnościowy" ]
      } else if ($(this).data('list') == 'parts') {
        options = [
          "Skontaktuj się z doradcą",
          "Oryginalne części i akcesoria",
        ]
      } else if ($(this).data('list') == 'fleet_center') {
        options = [
          "BMW 318d Gran Turismo - LP Centrum Flotowe",
          "BMW 518d - LP Centrum Flotowe",
          "BMW X3 20d xDrive - LP Centrum Flotowe",
          "BMW 330e - LP Centrum Flotowe",
          "BMW 530e xDrive - LP Centrum Flotowe",
          "BMW i3 - LP Centrum Flotowe"
        ]
      } else if ($(this).data('list') == 'vehicles') {
        options = [ $(this).data("subject") ]
      } else if ($(this).data('list') == 'download_certificate') {
        options = [ $(this).data("subject") ]
      } else if ($(this).data('list') == 'request_for_certificate') {
        options = [ $(this).data("subject") ]
      } else if ($(this).data('list') == 'calculator') {
        options = [ $(this).data("subject") ]
      }

      $.each(options, function(i,o) {
        $("#contact-form #client-topic").append(new Option(o, o));
      })

      if ($(this).data('list') == 'main') {
        $("#contact-form #client-topic option").first().attr("disabled",true)
      } else {
        $("#contact-form #client-topic").val($(this).data('subject'))
      }

      if ($(this).data('location')) {
        $("#contact-form select[name='client[location]']").val($(this).data('location'))
      } else {
        $("#contact-form select[name='client[location]']").val("")
      }

      if ($(this).data('description')) {
        $("#contact-form [name='client[description]']").val($(this).data('description'))
      } else {
        $("#contact-form [name='client[description]']").val("")
      }

      $("#contact-form #client_form_kind").val($(this).data('list'))

      $("#contact-form").find("input[name*=term_2_phone]").parents('.form-group').find("label").text("Telefonu/komunikatorów")

      //Blokowanie możliwości wyboru lokalizacji, przy formularzach o konkretny pojazd.
      if (["vehicles", "request_for_certificate", "calculator"].indexOf($(this).data("list")) == -1 || !$("#contact-form select[name='client[location]']").val()) {
        $("#contact-form select[name='client[location]'] option").removeAttr('disabled');
        $("#contact-form select[name='client[location]']").removeAttr('readonly');
      } else {
        $("#contact-form select[name='client[location]'] option:not(:selected)").attr('disabled',true);
        $("#contact-form select[name='client[location]']").attr('readonly',true);
      }

      if ($(this).data("list") == "download_certificate") {
        $("#contact-form").find("[name*=topic],[name*=location],[name*=description]").parent().hide()
        $("#contact-form p.desc").text("Po wysłaniu formularza pobrany zostanie certyfikat pojazdu.")
        $("#contact-form .title").text("Pobierz certyfikat")
        $("#contact-form [type=submit]").val("Pobierz")
        $("#contact-form [type=submit]").attr("data-disable-with","Pobierz")
      } else if ($(this).data("list") == "calculator") {
        $("#contact-form").find("[name*=description]").parent().hide()
        $("#contact-form .title").text("Kalkulator finansowania")
        $("#contact-form [name='client[description]']").val(
          "<br>" +
          "Link oferty: " + window.location.href + "<br>" +
          "Kwota kredytowania: " + $("#get-offer-calculation input[name=amount]").val() + " zł<br>" +
          "Okres kredytowania: " + $("#get-offer-calculation select[name=period]").val() + " msc <br>" +
          "Wyliczona rata: " + $("#get-offer-calculation #installment").text() + " zł <br>" +
          "Wysokość prowizji i typ oprocentowania: " + $("#get-offer-calculation input#commission[type=hidden]").val()
        )
        $("#contact-form").find("input[name*=term_2_phone]").parents('.form-group').find("label").text("Telefonu/komunikatorów*")
        $(".show-term").first().trigger("click");
        $("#contact-form p.desc").html("Nasz doradca skontaktuje się z Tobą <b>telefonicznie</b> w najkrótszym możliwym terminie.")
      } else {
        $("#contact-form").find("[name*=topic],[name*=location],[name*=description]").parent().show()
        $("#contact-form p.desc").text("Nasz doradca skontaktuje się z Tobą w najkrótszym możliwym terminie.")
        $("#contact-form .title").text("Formularz kontaktowy")
        $("#contact-form [type=submit]").val("Wyślij")
        $("#contact-form [type=submit]").attr("data-disable-with","Wyślij")
      }

      $("#contact-form").css('display', 'flex')
    })

    if (window.location.hash == "#kontakt") {
      $('.inch-menu__container .show-popup-form').click()
    }

    $('.move-to-form').click(function() {
      $('html,body').animate({
        scrollTop: $('.form form').last().offset().top
      }, 'slow');
    })


  }
}
