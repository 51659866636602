require('jquery-ui/ui/widget');

require('jquery-ui/ui/keycode');

require('jquery-ui/ui/widgets/mouse');

require('jquery-ui/ui/widgets/slider');

require('jquery-ui/themes/base/core.css');

require('jquery-ui/themes/base/slider.css');