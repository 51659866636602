import { options } from "knockout"

export const Offers = {

  model_list: [],

  prices: [
    { label: "20 000 PLN", value: "20 000" },
    { label: "25 000 PLN", value: "25 000" },
    { label: "30 000 PLN", value: "30 000" },
    { label: "35 000 PLN", value: "35 000" },
    { label: "40 000 PLN", value: "40 000" },
    { label: "50 000 PLN", value: "50 000" },
    { label: "65 000 PLN", value: "65 000" },
    { label: "80 000 PLN", value: "80 000" },
    { label: "100 000 PLN", value: "100 000" },
    { label: "150 000 PLN", value: "150 000" },
    { label: "200 000 PLN", value: "200 000" },
    { label: "300 000 PLN", value: "300 000" },
    { label: "400 000 PLN", value: "400 000" },
    { label: "500 000 PLN", value: "500 000" },
    { label: "1 000 000 PLN", value: "1 000 000" }
  ],

  mileages: [
    { label: "20 000 km", value: "20 000" },
    { label: "35 000 km", value: "35 000" },
    { label: "50 000 km", value: "50 000" },
    { label: "75 000 km", value: "75 000" },
    { label: "100 000 km", value: "100 000" },
    { label: "125 000 km", value: "125 000" },
    { label: "150 000 km", value: "150 000" },
    { label: "200 000 km", value: "200 000" },
    { label: "250 000 km", value: "250 000" },
  ],

  capacities: [
    { label: "500 cm3", value: "500" },
    { label: "600 cm3", value: "600" },
    { label: "750 cm3", value: "750" },
    { label: "1 000 cm3", value: "1 000" },
    { label: "1 250 cm3", value: "1 250" },
    { label: "1 500 cm3", value: "1 500" },
    { label: "2 000 cm3", value: "2 000" },
    { label: "2 500 cm3", value: "2 500" },
    { label: "3 000 cm3", value: "3 000" },
    { label: "4 000 cm3", value: "4 000" },
    { label: "5 000 cm3", value: "5 000" },
    { label: "6 000 cm3", value: "6 000" },
  ],

  powers: [
    { label: "50 KM", value: "50" },
    { label: "80 KM", value: "80" },
    { label: "120 KM", value: "120" },
    { label: "150 KM", value: "150" },
    { label: "200 KM", value: "200" },
    { label: "300 KM", value: "300" },
    { label: "500 KM", value: "500" },
  ],

  initialize: function() {
    if ($("#offers").length) {
      Offers.bindPaginate()
      Offers.bindMultiselect()
      Offers.bindBrandChange()
      Offers.bindShowFilters()
      Offers.bindAutocomplete()
      $(window).scroll(function(){
        if ($(".offer-list").height() > 750 && $(window).height() > 650) {
          if( $(window).scrollTop() > 616 ) {
            if( $(window).scrollTop() > ($(".offer-list").height() - 178) ) {
              $(".offer-list .form").addClass("absolute-bottom")
              $(".offer-list .form").removeClass("fixed")
            } else {
              $(".offer-list .form").addClass("fixed")
              $(".offer-list .form").removeClass("absolute-bottom")
            }
          } else {
            $(".offer-list .form").removeClass("fixed").removeClass("absolute-bottom")
          }
        }
      });
      $('form#offer-filters').submit(function() {
        $(".offer-list").addClass("loading")
      })
      if ($(".offer-list").length) {
        Offers.bindClickFilter()
        Offers.bindSort()
        Offers.bindBtnRemoveFilters();
        if (window.location.search.indexOf("openfilters") != -1 && $(window).width() <= 900) {
          $(".btn-show-filters").click()
        }
      }
      $('#opinions').slick({
        dots: false,
        arrows: true,
        arrow: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              draggable: false,
              swipe: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              draggable: false,
              swipe: false,
            }
          }
        ]
      })
    }
  },

  bindPaginate: function() {
    $('#offers .paginate a').attr("data-remote", "true")
    $('#offers .paginate a').click(function() {
      $(".offer-list").addClass("loading")
      $("html, body").animate({ scrollTop: 0 }, $(".offer-list").offset()["top"]);
    });
  },

  setModelList: function() {
    var model_selectize = $("#model-select")[0].selectize
    var list = Offers.model_list[$("#brand-select")[0].selectize.getItem($("#brand-select").val()).data("brandId")]
    model_selectize.setValue("", false)
    model_selectize.clearOptions()
    if (list) {
      $.each(list, function(index, opt) {
        model_selectize.addOption(opt)
      })
      model_selectize.enable(); 
      $(".filter-item.model").removeClass("disabled")
    } else {
      model_selectize.disable();
      $(".filter-item.model").addClass("disabled")
    }
  },
  

  bindClearSelectize: function() {
    $(".clear-selectize").unbind('click').click(function() {
      $(this).parents(".filter-item").find("select")[0].selectize.setValue("")
    })
  },

  bindBrandChange: function() {
    Offers.model_list = $("#model-select").data("modelList");
    $("#brand-select").selectize({
      sortField: "value",
      searchField: 'value',
      render: {
        item: function (data, escape) {
          return "<div class='item' data-brand-id='"+data.brandId+"' data-value='"+data.value+"'>"+escape(data.text)+"<i class='fas fa-times-circle clear-selectize'></i></div>"
        }
      }
    }).change(function() {
      Offers.setModelList();
      Offers.bindClearSelectize();
      $.rails.fire($('form#offer-filters')[0], 'submit')
    })
    $("#model-select").selectize({
      sortField: "value",
      searchField: 'value',
      render: {
        item: function (data, escape) {
          return "<div class='item' data-value='"+data.value+"'>"+escape(data.text)+"<i class='fas fa-times-circle clear-selectize'></i></div>"
        }
      }
    }).change(function() {
      Offers.bindClearSelectize();
      $.rails.fire($('form#offer-filters')[0], 'submit')
    })
    Offers.setModelList();
    Offers.bindClearSelectize();
    if ($("#model-select").data("value")) {
      $("#model-select")[0].selectize.setValue($("#model-select").data("value"), true)
    }
  },

  setSeriesSelect: function() {
    $('select[name=series]').parent().addClass('disabled')
    $('select[name=series]').multiselect('rebuild').multiselect('disable')
    if ($(".filter-item select[name='brand']").val() == "BMW") {
      $('select[name=series]').multiselect('rebuild')
      $('select[name=series]').parent().removeClass('disabled').find(".btn-multiselect").removeClass("disabled")
    }
  },

  setModelSelect: function() {
    $('select[name=model] option').attr('disabled', true)
    $('select[name=model]').parent().addClass('disabled')
    $('select[name=model]').multiselect('rebuild').multiselect('disable')
    if ($(".filter-item select[name='brand']").val()) {
      if ($(".filter-item select[name='brand']").val() != "BMW" || !$("select[name=series]").length) {
        $('select[name=model]').parent().removeClass('disabled')
        $('select[name=model] option[data-brand="'+$("select[name='brand']").val()+'"]').attr('disabled', false)
        $('select[name=model]').multiselect('rebuild')
      } else if ($("select[name=series]").val()) {
        $('select[name=model]').parent().removeClass('disabled')
        $('select[name=model] option[data-brand="'+$("select[name='brand']").val()+'"][data-series*="'+$('select[name=series]').val()+'"]').attr('disabled', false)
        $('select[name=model]').multiselect('rebuild')
      }
    }
  },

  bindAutocomplete: function() {
    jQuery(".filter-item input.price").autocomplete({
      minLength: 0,
      source: function( request, response ) {
        var arr = Offers.prices.filter( function(x) {
          return x["value"].replace(/[^0-9]/g,'').includes(request.term.replace(/[^0-9]/g,''))
        })
        if (request.term.length) {
          response($.merge([{ label: (request.term + " PLN"), value: request.term }],arr))
        } else {
          response(arr)
        }
      },
      select: function ( event, ui ) {
        var trigger = $(this).val().length ? '' : 'change'
        $(this).val(ui["item"]["value"]).trigger(trigger).blur();
      }
    }).focus(function () {
      jQuery(this).autocomplete("search");
    });

    jQuery(".filter-item input.mileage").autocomplete({
      minLength: 0,
      source: function( request, response ) {
        var arr = Offers.mileages.filter( function(x) {
          return x["value"].replace(/[^0-9]/g,'').includes(request.term.replace(/[^0-9]/g,''))
        })
        if (request.term.length) {
          response($.merge([{ label: (request.term + " km"), value: request.term }],arr))
        } else {
          response(arr)
        }
      },
      select: function ( event, ui ) {
        var trigger = $(this).val().length ? '' : 'change'
        $(this).val(ui["item"]["value"]).trigger(trigger).blur();
      }
    }).focus(function () {
      jQuery(this).autocomplete("search");
    });

    jQuery(".filter-item input.production_year").autocomplete({
      minLength: 0,
      source: $('.production_year').data('production-years'),
      select: function ( event, ui ) {
        var trigger = $(this).val().length ? '' : 'change'
        $(this).val(ui["item"]["value"]).trigger(trigger).blur();
      }
    }).focus(function () {
      jQuery(this).autocomplete("search");
    });

    jQuery(".filter-item input.capacity").autocomplete({
      minLength: 0,
      source: function( request, response ) {
        response(Offers.capacities.filter( function(x) {
          return x["value"].replace(/[^0-9]/g,'').includes(request.term.replace(/[^0-9]/g,''))
        }))
      },
      select: function ( event, ui ) {
        var trigger = $(this).val().length ? '' : 'change'
        $(this).val(ui["item"]["value"]).trigger(trigger).blur();
      }
    }).focus(function () {
      jQuery(this).autocomplete("search");
    });

    jQuery(".filter-item input.power").autocomplete({
      minLength: 0,
      source: function( request, response ) {
        response(Offers.powers.filter( function(x) {
          return x["value"].replace(/[^0-9]/g,'').includes(request.term.replace(/[^0-9]/g,''))
        }))
      },
      select: function ( event, ui ) {
        var trigger = $(this).val().length ? '' : 'change'
        $(this).val(ui["item"]["value"]).trigger(trigger).blur();
      }
    }).focus(function () {
      jQuery(this).autocomplete("search");
    });
  },

  bindClickFilter: function() {
    $('.filter-item input.filter-input, .filter-item select.filter-select').change(function() {
      $.rails.fire($('form#offer-filters')[0], 'submit')
    })
  },

  bindMultiselect: function() {
    $('select.multiselect').multiselect({
      enableFiltering: false,
      selectAllText: "Wszystkie",
      nSelectedText: "Wybrano",
      allSelectedText: "Wszystkie",
      filterPlaceholder: "Szukaj",
      nonSelectedText: "",
      buttonWidth: "100%",
      maxHeight: 300,
      buttonClass: "btn btn-multiselect",
      templates: {
        button: '<button type="button" class="multiselect dropdown-toggle" data-toggle="dropdown"><span class="multiselect-selected-text"></span><i class="fas fa-times-circle clear-input"></i></button>',
      }
    });
    $('.clear-input').click(function() {
      var select = $(this).parents('.filter-item').find('select.multiselect')
      select.val("")
      select.multiselect('refresh')
      select.change()
    })
  },

  bindBtnRemoveFilters: function () {
    $('.remove-filters').click(function(){
      $(".filter-item input, .filter-item select").val("")
      $(".filter-item select[name=brand]")[0].selectize.setValue("");
      $(".filter-item select[name=model]")[0].selectize.setValue("");
      $("form#offer-filters button[type=submit]").click()
    })
  },

  bindSort: function() {
    $("select.mobile-sort").change(function() {
      $("form#offer-filters button[type=submit]").click()
    });
    $('ul.sort li[data-column]').click(function() {
      var order = 'asc'
      if ($(this).hasClass('asc')) {
        $(this).addClass('desc').removeClass('asc')
        order = 'desc'
      } else if ($(this).hasClass('desc')) {
        $('ul.sort li').removeClass('asc').removeClass('desc')
        $("select.mobile-sort").val("").change()
        return true
      } else {
        $('ul.sort li').removeClass('asc').removeClass('desc')
        $(this).addClass('asc')
      }
      $("select.mobile-sort").val($(this).data('column') + "#" + order).change()
    })
  },

  bindShowFilters: function() {

    $(".btn-show-filters").click(function() {
      if ($(window).width() < 901) {
        $(".btn-show-filters, .mobile-sort, .offer-list").hide()
        $("#offers .header").show()
      }
    })
    $("#offer-filters .btn-submit").click(function() {
      $(window).scrollTop(0)
      if ($(window).width() < 901) {
        $(".btn-show-filters, .mobile-sort, .offer-list").show()
        $("#offers .header").hide()
      }
    })
  },

}

global.Offers = Offers