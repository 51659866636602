export const Pages = {

  initialize: function() {   
    if ($("#service, #test-drive").length) {
      Pages.bindServiceInfo()
    } else if ($('#vehicle-repurchase').length) {
      Pages.bindInputFiles()
    } else if ($('.inchcape-history__list').length) {
      Pages.bindClickYear()
    } 
  },

  bindInputFiles: function() {
    $("#new-files").change(function() {
      $.each(this.files, function(i, file) {
        let list = new DataTransfer();
        list.items.add(file)
        $("#files").append(
          '<div class="file col-sm-6 col-md-4 col-lg-3">' +
            '<div class="box">' +
              '<div class="img"><img></div>' +
              '<div class="remove-file"/>' +
            '</div>' +
            '<input type="file" name="client[files][]" multiple="multiple">' +
          '</div>'
        )
        $("#files .file").last().find("input")[0].files = list.files

        var preview = $('#files .file').last().find('img')[0]
        var reader  = new FileReader();
        reader.onloadend = function () {
          preview.src = reader.result;
        }
        reader.readAsDataURL(file);
      })
      Pages.bindRemoveFile()
    })
  },

  bindRemoveFile: function() {
    $('#files .file .remove-file').unbind('click').click(function() {
      $(this).parents('.file').remove()
    })
  },

  bindClickYear: function() {
    $(".inchcape-history__list-item").click(function(){
      const index = $('.inchcape-history__list-item').index($(this))
      if (index !== $('.inchcape-history__list-item--active').index()) {
        const windowWidth = $(window).width();
        const dataText = $(this).attr('data-content');
        const dataPicture = $(this).attr('data-picture');
        const allElems = $('.inchcape-history__list-item').length;
        let elemInRow = 2;
        if (windowWidth >= 1024) {
          elemInRow = 6;
        } else if (windowWidth >= 568) {
          elemInRow = 4;
        }
        const row = Math.floor(index / elemInRow);
        let after = row * elemInRow + (elemInRow - 1);
        if (after > allElems) {
          after = allElems - 1;
        }
        if ($('.inchcape-history__list-item-extended').length > 0) {
          $('.inchcape-history__list-item-extended').slideUp(300, 'swing', () => Pages.addItemData(after, dataPicture, dataText));
        } else {
          Pages.addItemData(after, dataPicture, dataText);
        }
      } else {
        $('.inchcape-history__list-item-extended').slideUp(300, 'swing', () => {
          $('.inchcape-history__list-item').removeClass('inchcape-history__list-item--active');
          $('.inchcape-history__list-item-extended').remove();
        });
      }
      $('.inchcape-history__list-item').removeClass('inchcape-history__list-item--active');
      $(this).addClass('inchcape-history__list-item--active');
    })
    $(window).resize(function() {
      $('.inchcape-history__list-item').removeClass('inchcape-history__list-item--active');
      $('.inchcape-history__list-item-extended').remove();
    });
  },
  
  addItemData: function(id, dataPicture, dataText) {
      $('.inchcape-history__list-item-extended').remove();
      const htmlElement = $(`<li class="inchcape-history__list-item-extended"></div>`);
      const htmlElementWrapper = $(`<div class="inchcape-history__list-item-extended-wrap"></div>`);
      if (dataPicture) {
          htmlElementWrapper.append(`<img class="inchcape-history__list-item-extended-picture" src="/image/${dataPicture}.jpeg" />`);
      }
      htmlElementWrapper.append(`<div class="inchcape-history__list-item-extended-text">${dataText}</div>`);
      htmlElement.append(htmlElementWrapper);
      $('.inchcape-history__list-item').eq(id).after(htmlElement);
      $('.inchcape-history__list-item-extended').slideDown(300);
  },

  bindServiceInfo: function() {
    $('.inch-service-info__navigation-item').click(function() {
      $('.inch-service-info__navigation-item').removeClass('inch-service-info__navigation-item--select')
      $(this).addClass('inch-service-info__navigation-item--select')
      $('.inch-service-info__block-information').removeClass('inch-service-info__block-information--active')
      $($(this).attr('href')).addClass('inch-service-info__block-information--active')
    })
  },

}
