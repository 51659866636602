export const JobOffers = {
  items: [],

  initialize: function() {   
    if ($("#job-offers").length) {
      JobOffers.items = $(".job-offer").remove()
      $('#job-offer-list .list').html(JobOffers.items)
      JobOffers.bindClickFilters()
      $('.roll-down').click(function() {
        $('.disclaimer_container').slideDown()
        $('.roll-down').hide()
        $('.roll-up').show()
      })
      $('.roll-up').click(function() {
        $('.disclaimer_container').slideUp()
        $('.roll-up').hide()
        $('.roll-down').show()
      })
      $(".move-to-job-offers").click(function() {
        $("#job-offer-list")[0].scrollIntoView({behavior: 'smooth'})
      })
    }
  },

  bindClickFilters: function() {
    $('#job-offer-list .filters select').change(JobOffers.showItems)
  },

  showItems: function() {
    var location = $("#job-offer-list .filters .locations").val()
    var sector = $("#job-offer-list .filters .sectors").val()
    $('#job-offer-list .list').html(
      JobOffers.items.filter(function(i, job_offer){
        if (location && $(job_offer).data('location').toString() != location) {
          return false
        }
        if (sector && $(job_offer).data('sector').toString() != sector) {
          return false
        }
        return true
      })
    )
  },

}
