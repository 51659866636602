require('jquery-ui/ui/widget');

require('jquery-ui/ui/position');

require('jquery-ui/ui/keycode');

require('jquery-ui/ui/unique-id');

require('jquery-ui/ui/widgets/autocomplete');

require('jquery-ui/ui/widgets/menu');

require('jquery-ui/themes/base/core.css');

require('jquery-ui/themes/base/autocomplete.css');

require('jquery-ui/themes/base/menu.css');