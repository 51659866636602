export const Posts = {
  items: [],

  initialize: function() {   
    if ($("#posts").length) {
      Posts.items = $(".inch-blog__item").remove()
      $('.inchcape-vehicle-list').html(Posts.items)
      Posts.bindClickFilters()
      Posts.bindClickHeader()
      Posts.bindClickClearFilters()
      Posts.bindShowFilters()
    }
  },

  bindClickHeader: function() {
   $('.inch-filters__group-header').click(function() {
     $(this).toggleClass('inch-filters__group-header--active')
     if ($(this).hasClass('inch-filters__group-header--active')) {
      $(this).next('fieldset').slideUp()
     } else {
      $(this).next('fieldset').slideDown()
     }
   })
  },

  setTags: function() {
    var html = ""
    $.each($('.inch-filters__input:checked'), function(i, input) {
      html += '<li class="inch-filters-tags__item" data-id="'+ $(input).attr('id') + '">'
      html += '<span class="inch-filters-tags__value">'
      html +=  $(input).parent().find('.inch-filters__input-label').text()
      html += '</span><span class="inch-filters-tags__close">x</span></li>'
    })
    $('ul.inch-filters-tags__items').html(html)
    Posts.bindClickTags()
  },

  bindClickTags: function() {
    $('ul.inch-filters-tags__items li').click(function() {
      $('#'+$(this).data('id')).click()
    })
  },

  bindClickFilters: function() {
    $('.inch-filters__input').change(Posts.showItems)
  },

  bindClickClearFilters: function() {
    $('.inch-vehicle-sort__clear-filter').click(function(){
      $('.inch-filters__input').prop("checked", false)
      $('.inchcape-vehicle-list').html(Posts.items)
      Posts.setItemCount()
      Posts.setTags()
    })
  },

  showItems: function() {
    var categories = $.map($('.inch-filters__input[name=categoryId]:checked'), function(input, i){return $(input).val()})
    var archive = $.map($('.inch-filters__input[name=archive]:checked'), function(input, i){return $(input).val()})
    $('.inchcape-vehicle-list').html(
      Posts.items.filter(function(i, post){
        if (categories.length && categories.indexOf($(post).data('categoryId').toString()) == -1) {
          return false
        }
        if (archive.length && archive.indexOf($(post).data('publishedAt').toString()) == -1) {
          return false
        }
        return true
      })
    )
    Posts.setItemCount()
    Posts.setTags()
  },

  setItemCount: function() {
    $('.js-record-count').text($('.inch-blog__item').length)
  },

  bindShowFilters: function() {
    $('.inchcape-vehicle__toggle').click(function() {
      $('.inchcape-vehicle-list__content').toggleClass('inchcape-vehicle-list__content--show')
      $('.inchcape-vehicle-list__filter').toggleClass('inchcape-vehicle-list__filter--show')
    })
  },

}