global.$ = jQuery;
global.jQuery = jQuery;
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("slick-carousel")
require('webpack-jquery-ui');
require('webpack-jquery-ui/autocomplete');
require('bootstrap');
require('bootstrap-multiselect');
require("slick-lightbox")
require('webpack-jquery-ui/slider');
require("jquery-ui-touch-punch");
require('webpack-jquery-ui/css');
require("packs/page_with_maps");
require("@selectize/selectize")
import '@selectize/selectize/dist/css/selectize.css';
var loadTouchEvents = require('jquery-touch-events');
loadTouchEvents(global.$);

import { Form } from 'packs/form';
import { Home } from 'packs/home';
import { Offer } from 'packs/offer';
import { Offers } from 'packs/offers';
import { Pages } from 'packs/pages';
import { Posts } from 'packs/posts';
import { JobOffers } from 'packs/job_offers';

$(window).bind('popstate', function(){
  window.location.href = window.location.href;
});

$(document).on("turbolinks:load", function(){
  $('[data-toggle="tooltip"]').tooltip()
  bindMenu();
  bindImageHover();

  $("body").on('click', ':not(.inch-menu__item)', function (e) {
    $(".inch-menu__list-item.active").attr('data-visible', "false").removeClass('active')
    $('.inch-menu__content.active').attr('data-visible', "false").removeClass('active')
    $('inch-menu__item.active').attr('data-visible', "false").removeClass('active')
  });

  if (localStorage.getItem('hide-cookie') != "true") {
    $('.inch-cookie-popup').css('display','block')
    $('.inch-cookie-popup__button.inch-cookie-popup__btn--akcept').click(function(){
      localStorage.setItem('hide-cookie', true)
      $('.inch-cookie-popup').css('display','none')
    })
  }

  if (sessionStorage.getItem('hide-online-quote') != "true") {
    $('.online-quote-widget').css('display','block')
    $('.online-quote-widget .close').click(function(){
      sessionStorage.setItem('hide-online-quote', true)
      $('.online-quote-widget').css('display','none')
    })
  }

  try { Form.initialize() } catch(err) { console.log("form errors") }
  try { Home.initialize() } catch(err) { console.log("home errors") }
  try { Offer.initialize() } catch(err) { console.log(err) }
  Offers.initialize() //} catch(err) { console.log("offers errors") }
  try { JobOffers.initialize() } catch(err) { console.log("offers errors") }
  try { Pages.initialize() } catch(err) { console.log("pages errors") }
  try { Posts.initialize() } catch(err) { console.log("posts errors") }

  if (window.location.search.includes("contact")) {
    $("#inch-menu-bg .show-popup-form").click()
  }

  $('.open-indicata').click(function() {
    window._lgconf = {
      dealerId:'4cb6d8ab-3439-4fa8-a48e-16c11799cf93',
      source: "Inchcape",
      inline:true,
      position: {
        left:'10px',
        bottom:'10px'
      }, i18n: {
        widgetTitle:'What\'sMyCarWorth?',
        buttonText:'Startvaluation'
      }
    }
    var s=document.createElement('script')
    s.type='text/javascript'
    s.src='https://lg.indicata.com/widget/v1/widget.js'
    document.getElementsByTagName('head')[0].appendChild(s)
  })

  document.querySelectorAll('.number-separator').forEach((item) => {
    item.addEventListener('input', (e) => {
      if (/^[0-9.\s]+$/.test(e.target.value)) {
        e.target.value = parseFloat(
          e.target.value.replace(/\s/g, '')
        ).toLocaleString('pl');
      } else {
        e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      }
    });
  });
});

function bindImageHover() {
  $("a .image-controls .image-control").hover(function() {
    var box = $(this).parent().parent()
    box.find(".image-control").removeClass("hovered")
    $(this).addClass("hovered")
    box.find(".image").hide()
    box.find(".image-"+$(this).data("image")).show()
  })
  $(".home-offer-item .image, .offer .image" ).on( "swiperight", function() {
    var arr = Array.from($(this).parent().children())
    var index = arr.indexOf(this)
    if (index > 0) {
      index = index - 1
      $(this).parent().css("transform","translateX(-"+(index*100)+"%)")
      $(this).parent().parent().find(".image-controls .image-control").removeClass("hovered")
      $($(this).parent().parent().find(".image-controls .image-control")[index]).addClass("hovered")
    }
  });
  $(".home-offer-item .image, .offer .image" ).on( "swipeleft", function() {
    var arr = Array.from($(this).parent().children())
    var index = arr.indexOf(this)
    if (index+1 < arr.length) {
      index = index + 1
      $(this).parent().css("transform","translateX(-"+(index*100)+"%)")
      $(this).parent().parent().find(".image-controls .image-control").removeClass("hovered")
      $($(this).parent().parent().find(".image-controls .image-control")[index]).addClass("hovered")
    }
  });
}
global.bindImageHover = bindImageHover;

function sanitizeString( str ) {
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
}
global.sanitizeString = sanitizeString;


function bindMenu() {
  const menuButton = $('.inch-menu__button--menu');
  const menuMainElement = $('.inch-menu__container.inch-menu__layout-top-mobile');
  const listChildren = $(menuMainElement).find('li.inch-menu__item[data-children="true"]');
  const aItem = $(menuMainElement).find('a.inch-menu__item');
  const menuActiveClassName = 'active';
  let currentIndex;
  listChildren.click(liChild => {
      const currentTarget = $(liChild.currentTarget);
      const curentLiClickDeph = parseInt(currentTarget.attr('data-deph'), 10);
      liChild.stopPropagation();
      const currentTargetChildren = currentTarget.children('.inch-menu__content');
      const currentTargetDepth = currentTarget.find('.inch-menu__list-item[data-deph="' + +curentLiClickDeph + '"]');
      if (parseInt(currentTarget.attr('data-deph'), 10) === 1 && currentIndex !== currentTarget.index()) {
          currentIndex = currentTarget.index();
          hideMenu();
      }
      currentTarget
          .toggleClass(menuActiveClassName);
      currentTargetChildren
          .toggleClass(menuActiveClassName);
      currentTarget.find('.inch-menu__list-item[data-deph="' + +curentLiClickDeph + '"]')
          .toggleClass(menuActiveClassName);
      if (currentTarget.hasClass(menuActiveClassName)) {
          currentTarget.attr('data-visible', 'true');
      }
      else {
          currentTarget.attr('data-visible', 'false');
      }
      if (currentTargetChildren.hasClass(menuActiveClassName)) {
          currentTargetChildren.attr('data-visible', 'true');
      }
      else {
          currentTargetChildren.attr('data-visible', 'false');
      }
      if (currentTargetDepth.hasClass(menuActiveClassName)) {
          currentTargetDepth.attr('data-visible', 'true');
      }
      else {
          currentTargetDepth.attr('data-visible', 'false');
      }
  });
  aItem.click((event) => {
      hideMenu();
      event.stopPropagation();
  });
  function toggleMenuList() {
      $(menuMainElement).toggleClass('active');
      $('body').toggleClass('no-scroll');
      $('html').toggleClass('no-scroll');
  }
  function hideMenu() {
      $('.inch-menu__item[data-children="true"]').attr('data-visible', 'false').removeClass('active')
          .find('.inch-menu__content, .inch-menu__list-item').attr('data-visible', 'false').removeClass('active');
  }
  menuButton.click(e => {
      $(e.currentTarget).find('.button-open').toggleClass('active');
      $(e.currentTarget).find('.button-close').toggleClass('active');
      toggleMenuList();
  });
}

function toggleCliboard(btn) {
  $.get( $(btn).attr("data-url"), function( data ) {
    $(btn).toggleClass('added')
    if ($(btn).hasClass('added')) {
      $("#note_cliboard").show()
    } else if ($('#offers.cliboard').length) {
      $(btn).parents('.offer').parent().remove()
      if (!$('.offer').length){
        $('.empty-box').show()
      }
    }
  });
}
global.toggleCliboard = toggleCliboard;
function toggleCompare(btn) {
  $.get( $(btn).attr("data-url"), function( data ) {
    if ($('#offers.compare').length) {
      if ($("tr").first().find("td:visible").length == 2) {
        location.reload()
      } else {
        var index = $("tr").first().find("td").index($(btn).parents("td")) + 1
        $("tr td:nth-child("+index+")").animate({width:'toggle'},350);
      }
    } else {
      $(btn).toggleClass('added')
      if ($(btn).hasClass('added')) {
        $("#note_compare").show()
      }
    }
  });
}
global.toggleCompare = toggleCompare;
